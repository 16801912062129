import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

export default function InteractiveBio({ parentWidth }) {
  const svgRef = useRef(null);
  const width = parentWidth;
  const height = 100;
  const data = [
    [
      2013,
      "Bachelor of Electrical Engineering",
      "Isfahan University of Technology",
    ],
    [
      2019,
      "Master of Architecture",
      "University of North Carolina at Charlotte",
    ],
    [
      2025,
      "PhD of Computer Sience",
      "University of North Carolina at Charlotte",
    ],
  ];

  useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .style("overflow", "visible")
      .style("margin", "auto")
      .style("margin-top", "20px");

    svg.attr("width", width).attr("height", height).attr("z-index", 99);

    // scale
    const xScale = d3.scaleLinear().domain([2000, 2030]).range([0, width]);

    //axis
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(data.length)
      .tickFormat((d) => d3.timeFormat(d, "%Y"))
      .ticks(5);

    svg.append("g").call(xAxis).attr("name", "xAxis");

    d3.selectAll(".tick text").attr("font-size", "12");

    // labeling
    const labels = svg.append("g").attr("name", "labels");

    labels
      .append("text")
      .attr("x", width / 2)
      .attr("y", 80)
      .text("Education Timeline")
      .attr("text-anchor", "middle")
      .attr("font-size", "14pt")
      .attr("font-weight", "bold");

    labels
      .selectAll()
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d[0]))
      .attr("y", (d, i) => ((-1) ** (i + 1) * height) / 3)
      .text((d) => d[1])
      .attr("text-anchor", "middle")
      .attr("fill", "indianred")
      .attr("font-size", "10pt")
      .attr("font-weight", "bold");

    // data points group
    var dataPoints = svg.append("g").attr("name", "dataPoints");

    // Create a group for each data point
    const pointGroups = dataPoints
      .selectAll("g.pointGroup")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "pointGroup")
      .attr("transform", (d) => `translate(${xScale(d[0])}, ${0})`);

    // Add circle to each group of pointGroups
    pointGroups
      .append("circle")
      .attr("class", "dpoint")
      .attr("r", 6)
      .attr("fill", "indianred")
      .attr("stroke", "indianred");

    // Add the tooltip rect to each pointGroups (starts hidden at opacity=0)
    pointGroups
      .append("rect")
      .attr("name", "tooltip")
      .attr("x", 0)
      .attr("y", 0)
      .attr("height", 24)
      .attr("width", 20)
      .attr("fill", "indianred")
      .attr("rx", 4) // make it slightly rounded corners, optional
      .attr("opacity", 0);

    // add Tooltip text to pointGroups
    pointGroups
      .append("text")
      .attr("class", "tooltip-text")
      .attr("x", 5)
      .attr("y", 16)
      .attr("fill", "white")
      .attr("font-size", "10pt")
      .text((d) => d[2])
      .attr("opacity", 0);

    // Mouse event handlers (attach to the entire group)
    pointGroups
      .selectAll("circle")
      .on("mouseover", function (event, d) {
        d3.select(this).transition().duration(20).attr("cursor", "pointer");
        //select parent (pointGroup)
        const parentG = d3.select(event.currentTarget.parentNode);
        var tooltipWidth = parentG.node("text").getBBox().width;

        parentG
          .select("rect")
          .transition()
          .duration(200)
          .attr("width", tooltipWidth)
          .attr("y", -30) // move the rect above the circle
          .attr("opacity", 0.8);

        // Show the text as well
        parentG
          .select("text.tooltip-text")
          .transition()
          .duration(200)
          .attr("y", -12) // inside the rect
          .attr("z-level", 99)
          .attr("opacity", 1);
      })
      .on("mouseout", function () {
        // Fade out the rect

        d3.select(this.parentNode)
          .select("rect")
          .transition()
          .duration(200)
          .attr("y", 0)
          .attr("opacity", 0);

        // Fade out the text
        d3.select(this.parentNode)
          .select("text.tooltip-text")
          .transition()
          .duration(200)
          .attr("y", 16)
          .attr("x", 5)
          .attr("opacity", 0);

        d3.select(this).attr("cursor", "default");
      });
  }, [data, width]);

  return (
    <svg
      ref={svgRef}
      style={{ padding: "3vmin" }}
    />
  );
}
