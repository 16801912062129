import React, { useEffect, useRef, useState } from "react";

import profilePic from "../../images/profile_pic_s.jpg";
import InteractiveBio from "../../components/vis/interactiveBio/InteractiveBio";
import "./Bio.css";

export default function Bio() {
  const visParent = useRef();
  const [VisWidth, setVisWidth] = useState(null);
  useEffect(() => {
    if (visParent.current) {
      const width = visParent.current.offsetWidth;
      setVisWidth(width);
    }
  }, [visParent]);

  return (
    <div
      className="bioLayout"
      ref={visParent}
    >
      <div className="bioHeader">
        <img
          src={profilePic}
          className="profile"
          alt={"Milad Rogha"}
        />
        <h1>
          <code className="customCode">{"print('Hello, World!')"}</code>
        </h1>
      </div>
      <div className="textHolder">
        <p className="tx1">
          PhD Candidate with expertise in <strong>machine learning</strong>,
          <strong> data analysis</strong>, and <strong> NLP</strong>, leveraging
          large-scale datasets and AI-driven solutions to solve complex
          problems. Experienced in building scalable data pipelines, optimizing
          large language models, and developing interactive visualizations.
          Passionate about transforming raw data into actionable insights and
          deploying AI applications that drive real-world impact.
        </p>

        <p className="tx1">
          I am a Ph.D. student at the Department of Computer Science, College of
          Computing and Informatics, University of North Carolina at Charlotte.
          My research interests include human-AI interaction, data
          visualization, explainable AI, and social computation. The rise in the
          application of artificial intelligence, especially in the fields
          requiring critical decision-making, raised questions regarding the
          autonomy and reliability of the predictions made by these models. My
          research focus is twofold: How can data visualization techniques
          increase the efficiency and efficacy of AI-human decision-making? How
          do we convey the model results to lay users in such a way that it is
          persuasive?
        </p>
      </div>
      <div className="visContainer">
        {VisWidth ? <InteractiveBio parentWidth={VisWidth} /> : ""}
      </div>
    </div>
  );
}
